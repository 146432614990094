import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "casinofiz",
  casinoId: 94,
  GA_TRACKING:'G-XCKHDZZ16V',

  rivalChatGroupName: "Casino Fiz",
  prettyName: "Casino Fiz",
  contactEmail: "support@casinofiz.com",
  docsEmail: "documents@casinofiz.email",

  assetsPath: "assets/brands/fiz",

  //SEO
  metaDescription: "Join Online Casino Fiz, get no deposit bonus, free spins and real cash welcome bonus. Play Instanet slots, table games, and gamble with live dealers. Enjoy secure transactions and fast payouts wherever crypto payments are accepted.",
  logoName: "casino-fiz-online-casino-logo.png",
  logoAltText: "The official Casino Fiz logo is of bright green and yellow coloring, combined in a more playful design, which should speak to gamblers as some kind of online casino where one can get involved with a variety of slots, table games, and other cool bonuses. Play safe; get dynamic online gambling with Casino Fiz."
};


